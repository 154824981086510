<template>
<div>

  <v-card>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    <v-card-title>
      TPO Program-Program Report
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table    
      :headers="headers"
      :items="program_list"
      :search="search"
    >
     <template v-slot:item.program="{ item }">
       <v-simple-table>
                                    <template v-slot:default>
                                        
                                        <tbody>
                                            <tr v-for="it in item.program_array" :key="it.id">                                               
                                                
                                                <td>
                                                    {{ it.program }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
        
        </template>
    
    </v-data-table>

  </v-card>
  </div>
</template>

<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
  data: () => ({
    dialogadd:false,
    reg:"",
    com:"",
    // dialog_icon:false,
     savedata: {
                file: null,
                id:"",
            },
    id:"",
    snackbar_msg: "",
    color: "",
    snackbar: false,
    placement_type_list: [],
    learner_list: [],
   year_list: [],
    sem_list: [],
    program_array:[],
    program_list: [],
    programtype_list:[],
    search: "",
    year: "",
    selectedprgtype:null,
    program: null,    
    
    headers: [
      { text: "Sr. No.", value: "srno"   },
      { text: "TPO Program", value: "tpoprogram"  },
      { text: "Program", value: "program"},
          
    
    ],
    
headers1: [
      
      { text: " Organization", value: "org"  },
      { text: "Program", value: "program"},
          
    
    ],


  excelfile: null,
    exceldialog: false, 
    url: "",
    excelerrordialog: false,
    notfoundstudent: [],
    

  }),
  mounted() {
    axios    
      .post("/TPO/getTPO_Program_Program_Report")
      .then(res => {    
        if (res.data.msg == "200") {          
          
          this.program_list = res.data.program_list;
           

          this.program_list.push({
                    name: 'All',
                    text: ''
          })
         
          this.institute="All"
    
          console.log(" fetching data!");
        } else {
          console.log("error fetching data!");
        }
      });
      
  },

  
  methods: {
    download(item){
      //console.log(item);
       var data = {
        id: item.id,
        learnerid:item.learner_id,

      };

      axios.post("/TPO/downloadofferletterinReport", data).then((res) => {
        if (res.data.msg == "200") {
          window.open(res.data.offerurl, "_blank");
        }
      });
    },
     exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Placement_REPORT", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
     


   dialogicon(item){
          // alert("helooooo");
          //  alert(item.registration_number);
           this.reg=item.registration_number;
           this.com=item.company;
      this.dialogadd=true;
   },
    fetchReport() { 
        const data = {
        instituteid: this.institute,
        year_id:this.year,
        program_id:this.program,
      };
        axios
        .post("TPO/getOfferletterreport",data)
        .then((res) => {
          if (res.data.msg == "200") {
           this.learner_list = res.data.learner_list;
           //console.log("learner ");
           //console.log(this.learner_list)
            if(this.learner_list.length < 1){
              this.showSnackbar("red", "Data Not Found");
            }
          } else {
           this.showSnackbar("green", res.data.msg1);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },
    getPrograms(){
     // alert("in get programs"+this.institute);     
           const data = {
        instituteid: this.institute,
      };
        axios
        .post("TPO/getPrograms",data)
        .then((res) => {
          if (res.data.msg == "200") {
           this.program_list = res.data.program_list;
         
            this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          } else {
           this.showSnackbar("green", res.data.msg1);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
      
    },





    getprogramTypelist(){
       this.programtype_list=[]
      var params = {
        instituteid: this.institute,
      };

      axios.post("/TPO/getprogramTypelist", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.programtype_list = res.data.programtype_list;
          //console.log("this.programtype_list");
          //console.log(this.programtype_list);
        } else {}
      });
    },
    
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
        clear() {
      this.excelfile = null;
      this.exceldialog = false;
    },
       closeerrordialog() {
      this.clear();
      this.excelerrordialog = false;
    },
  }
};
</script>